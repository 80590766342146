import React, { Component, createRef } from 'react'
import {
    Image,
    View,
    Pressable,
    Text,
    FlatList,
    PressableProps,
} from 'react-native'
import styles from './styles'
/**
 * @deprecated Since version 1.1.4 Use Carousel in next release
 */
type ItemPressableProps = PressableProps

//@ts-expect-error
interface Item extends ItemPressableProps {
    id: number
    title: string
    image: string
    price: string
    titleText: string
    descText: string
    titleText2: string
    descText2: string
    children: any
}

export interface ItemProps extends Omit<ItemPressableProps, 'onPress'> {
    products: any
    mainView?: object
    imageView?: object
    titleTextStyle?: object
    descTextStyle?: object
    priceBlockStyle?: object
    priceTextStyle?: object
    rightIconPosition?: object
    leftIconPosition?: object
    btnStyle?: object
    gradientColor1?: string
    gradientColor2?: string
    btnTextStyle?: object
    containerStyle?: object
    onPressTitle?: (item) => void
}

export interface ItemState {
    visibleItem?: any
}

class WebCarousel extends Component<ItemProps, ItemState> {
    listRef = createRef<FlatList<Item>>()
    constructor(props) {
        super(props)
        this.listRef = createRef()
        this.state = {
            visibleItem: [],
        }
    }

    onViewCallBack = (viewableItems) => {
        this.setState({ visibleItem: viewableItems })
    }

    scrollToIndex = (index, animated = true) => {
        this.listRef.current &&
            this.listRef.current.scrollToIndex({
                index: index,
                animated: animated,
            })
    }

    renderText = ({}) => {
        return <Text>Hii</Text>
    }

    render() {
        const { visibleItem } = this.state
        const {
            products,
            containerStyle,
            mainView,
            imageView,
            titleTextStyle,
            descTextStyle,
            priceBlockStyle,
            priceTextStyle,
            onPressTitle,
            ...otherPressableProps
        } = this.props

        const renderItem = ({ item }: { item: Item }) => {
            const {
                titleText,
                descText,
                titleText2,
                descText2,
                price,
                children,
            } = item

            return (
                <View style={[styles.mainView, mainView]}>
                    <Pressable
                        style={[styles.blockView, imageView]}
                        onPress={() => onPressTitle(item)}
                        {...otherPressableProps}
                    >
                        <Text style={[styles.titleText, titleTextStyle]}>
                            {titleText}
                        </Text>
                        <Text style={[styles.descText, descTextStyle]}>
                            {descText}
                        </Text>
                    </Pressable>
                    <View>
                        <Text style={[styles.titleText, titleTextStyle]}>
                            {titleText2}
                        </Text>
                        <Text style={[styles.descText, descTextStyle]}>
                            {descText2}
                        </Text>
                        <View style={[styles.priceBlock, priceBlockStyle]}>
                            <Text style={[styles.priceText, priceTextStyle]}>
                                {price}
                            </Text>
                            {children}
                        </View>
                    </View>
                </View>
            )
        }

        return (
            <View style={[{ flexDirection: 'row' }, containerStyle]}>
                {visibleItem?.viewableItems?.length &&
                visibleItem?.viewableItems[0].index === 0 ? null : (
                    <Pressable
                        testID={'leftIconButton'}
                        style={[
                            styles.leftIconPosition,
                            this?.props?.leftIconPosition,
                        ]}
                        onPress={() => {
                            const index = products.findIndex((element) => {
                                return (
                                    element.id ==
                                    visibleItem?.viewableItems[0].item.id
                                )
                            })
                            this.scrollToIndex(index - 1)
                        }}
                    >
                        <View style={styles.circle}>
                            <Image
                                source={{
                                    uri: 'https://i.imgur.com/wk0j48w.png',
                                }}
                                style={styles.iconStyle}
                            ></Image>
                        </View>
                    </Pressable>
                )}

                <View style={{ width: '90%' }}>
                    <FlatList
                        data={products}
                        ref={this.listRef}
                        horizontal={true}
                        renderItem={renderItem}
                        onEndReachedThreshold={0.5}
                        scrollEnabled={false}
                        showsHorizontalScrollIndicator={false}
                        onViewableItemsChanged={this.onViewCallBack}
                        keyExtractor={(item) => `${item.id}`}
                    />
                </View>

                <Pressable
                    testID={'rightIconButton'}
                    style={[
                        styles.rightIconPosition,
                        this.props.rightIconPosition,
                    ]}
                    onPress={() => {
                        const index = products.findIndex((element) => {
                            return (
                                element.id ==
                                visibleItem?.viewableItems[
                                    visibleItem?.viewableItems.length - 1
                                ].item.id
                            )
                        })
                        this.scrollToIndex(index - 1)
                        // if (visibleItem?.viewableItems.length == 1) {
                        // this.scrollToIndex(index)
                        // } else {
                        //   this.scrollToIndex(index - 1)
                        // }
                    }}
                >
                    <View style={styles.circle}>
                        <Image
                            source={{ uri: 'https://i.imgur.com/wWX59PK.png' }}
                            style={styles.iconStyle}
                        ></Image>
                    </View>
                </Pressable>
            </View>
        )
    }
}

export default WebCarousel
