import React, { FC } from 'react';
import {
    SafeAreaView,
    View,
    FlatList,
    Pressable,
    PressableProps,
    ViewStyle,
    TextStyle,
    StyleProp
} from 'react-native';
import styles from './styles';

import { Typography } from '../Typography/Typography';

export interface ListItemProps extends Omit<PressableProps, 'onPress'> {
    /**
    * ```javascript
    * export type ListDataItem = { 
    *       title: string; 
    *       iconLeft?: React.ReactNode; 
    *       iconRight?: React.ReactNode; 
    *       children?: React.ReactNode; 
    *       details?: string;
    *    };```
    */
    listData: Array<ListDataItem>;
    containerStyle?: StyleProp<ViewStyle>;
    textViewStyle?: TextStyle;
    titleTextStyle?: TextStyle;
    detailTextStyle?: TextStyle;
    listItemStyle?: StyleProp<ViewStyle>;
    /** OnPress will be called passing the item that trigger the event*/
    onPress?: (item: ListDataItem) => void;
}

export type ListDataItem = {
    title: string;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    children?: React.ReactNode;
    details?: string;
};
/**
 * The props extends this interface `extends Omit<PressableProps, 'onPress'>`.
 * 
 */
const ListItem: FC<ListItemProps> = ({
    listData,
    containerStyle,
    textViewStyle,
    titleTextStyle,
    detailTextStyle,
    listItemStyle,
    onPress,
    ...otherPressableProps
}) => {
    const ItemDivider = () => <View style={styles.itemSeparator} />;

    const renderItem = ({ item }: { item: ListDataItem }) => (
        <Pressable
            style={[styles.container, listItemStyle]}
            onPress={() => onPress(item)}
            {...otherPressableProps}
        >
            <View style={styles.column}>
                <View style={styles.row}>
                    {item?.iconLeft}
                    <View style={[styles.textView, styles.marginHorizontal, textViewStyle]}>
                        <Typography style={[styles.text, titleTextStyle]}>
                            {item?.title}
                        </Typography>
                        <Typography style={{ ...styles.subText, ...detailTextStyle }}>
                            {item?.details}
                        </Typography>
                    </View>
                    {item?.iconRight && (
                        <View style={styles.iconRightContainer}>
                            {item.iconRight}
                        </View>
                    )}
                </View>
                {item?.children && (
                    <View style={styles.childrenContainer}>
                        {item.children}
                    </View>
                )}
            </View>
        </Pressable>
    );

    return (
        <FlatList
            style={containerStyle}
            data={listData}
            keyExtractor={(item, index) => index.toString()}
            renderItem={renderItem}
            ItemSeparatorComponent={ItemDivider}
        />
    );
};

export default ListItem;
