import React from 'react'

import { Switch, SwitchProps } from '@rneui/themed'

export interface ToggleProps extends SwitchProps {}

export function Toggle(props: ToggleProps) {
    const { value, ...rest } = props

    return (
        <Switch color={value ? '#6200EA' : '#E2E8F0'} value={value} {...rest} />
    )
}
