import React from 'react'
import { View } from 'react-native'

import { SmallMediumCardProps } from './interface'
import { styles } from './styles'

export const SmallMediumCardBase = ({
    topIcon,
    topIconPosition,
    image,
    children,
}: SmallMediumCardProps) => {
    return (
        <>
            {topIcon && (
                <View
                    style={[
                        styles.topIconStyle,
                        {
                            alignItems:
                            topIconPosition === 'left'
                                ? 'flex-start'
                                : 'flex-end',
                        }
                    ]}
                >
                    {topIcon}
                </View>
            )}
            {image && image}
            {children}
        </>
    )
}
