import React, { useContext, useEffect, useRef } from 'react'

import { BuilderContext } from '../components/BuilderProvider/Builder.context'

const OutsideClickHandler = ({ children, onPress }) => {
    const isMountRef = useRef(true)
    const state = useContext(BuilderContext)
    const { newClick, providerChecker: ProviderChecker } = state

    useEffect(() => {
        if (isMountRef.current) {
            isMountRef.current = false
            return
        }

        typeof onPress === 'function' && onPress()
    }, [newClick])

    return <ProviderChecker>{children}</ProviderChecker>
}

export default OutsideClickHandler
