import React from 'react'
import { View, Text, TouchableOpacity, TextInput } from 'react-native'
import styles from './styles'
import { DateInputProps } from './interface'

export const DateInput: React.FC<DateInputProps> = ({
    hideInput,
    dateInputContainerStyles,
    renderInput,
    inputValue,
    view,
    setView,
}) => {
    if (hideInput) return null

    return (
        <View style={[styles.dateInputContainer, dateInputContainerStyles]}>
            {renderInput !== undefined ? (
                renderInput(inputValue)
            ) : (
                <View
                    style={{
                        width: '100%',
                        flexShrink: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderWidth: 1,
                        borderColor: '#E5E7EB',
                        borderRadius: 8,
                    }}
                >
                    <TextInput
                        style={styles.dateInput}
                        value={inputValue}
                        editable={false}
                        selectTextOnFocus={false}
                    />
                    <TouchableOpacity
                        style={styles.calendarIcon}
                        onPress={() =>
                            setView(view === 'monthly' ? 'weekly' : 'monthly')
                        }
                    >
                        <Text>📅</Text>
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
