import React from 'react'
import { Text, View } from 'react-native'
import { styles } from './styles'
import { Size } from '../../utils/constants'
import { CardLoading } from './CardLoading'
import { CardTitle } from './CardTitle'
import { SmallMediumCardBase } from './SmallMediumCardBase'
import { SmallMediumCardProps } from './interface'

export const SmallMediumCard: React.FC<
    SmallMediumCardProps & { size?: Size; type?: string }
> = (props) => {
    const {
        containerStyle,
        loading,
        topIcon,
        topIconPosition,
        image,
        titleTextStyle,
        title,
        descriptionTextStyle,
        description,
        price,
        priceTextStyle,
        pressableProps,
        size,
        testID,
        actionButton,
        actionButtonContainerStyle,
        cardTitleStyle
    } = props

    const isSmallCard = size === Size.Small
    const isMediumCard = size === Size.Medium

    if (loading) {
        return (
            <CardLoading
                testID={`${testID}-loading`}
                size={size}
                topIconPosition={topIconPosition}
                containerStyle={containerStyle}
            />
        )
    }

    const RenderCardTitle = () => (
        <CardTitle
            title={title}
            titleTextStyle={titleTextStyle}
            description={description}
            descriptionTextStyle={descriptionTextStyle}
            price={price}
            isMediumCard={isMediumCard}
            priceTextStyle={priceTextStyle}
            cardTitleStyle={cardTitleStyle}
            {...pressableProps}
        />
    )

    return (
        <>
            <View
                testID={`${testID}-container`}
                style={[styles.containerStyle, containerStyle]}
            >
                <SmallMediumCardBase
                    topIcon={topIcon}
                    topIconPosition={topIconPosition}
                    image={image}
                    testID={`${testID}-card-base`}
                >
                    <RenderCardTitle />
                </SmallMediumCardBase>

                {actionButton && (
                    <View
                        style={[
                            styles.actionButtonContainerStyle,
                            actionButtonContainerStyle,
                        ]}
                    >
                        {actionButton}
                    </View>
                )}
            </View>
        </>
    )
}
