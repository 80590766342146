import React, { useMemo, useState, FC } from 'react'
import { ViewStyle, Pressable, PressableProps, StyleProp } from 'react-native'
import style from './style'
import { Image, View, Text } from 'react-native'
import { usePlatfomHook } from '../../../utils/getPlatformType'
import SideNavigation, {
    SideNavigationProps,
    SideNavigationItem,
} from '../SideNavigation/sideNavigation'
import hamburgerImage from '../../../assets/images/hamburger.png'

export interface TopNavigationBarProps {
    testID?: string
    containerStyle?: StyleProp<ViewStyle>
    headerLogoImage?: React.ReactNode
    searchInputBar?: React.ReactNode
    hamburgerIcon?: React.ReactNode
    hamburgerButton?: HamburgerButtonProps
    navigationItems?: SideNavigationProps
    rightContainer?: Array<RightContainer>
    rightIconStyle?: StyleProp<ViewStyle>
    rightContainerStyle?: StyleProp<ViewStyle>
    showResponsiveIcon?: boolean
}

export interface RightContainer {
    desktop?: React.ReactNode
    mobile?: React.ReactNode
    tablet?: React.ReactNode
}

interface HamburgerButtonProps extends PressableProps {
    onPress?: () => void
}

export const NavBar: FC<TopNavigationBarProps> = ({
    testID,
    containerStyle,
    headerLogoImage,
    searchInputBar,
    hamburgerIcon,
    hamburgerButton,
    navigationItems,
    rightContainer,
    rightIconStyle,
    rightContainerStyle,
    showResponsiveIcon,
}) => {
    const [topNavContainerHeight, setTopNavContainerHeight] = useState(0)
    const [showDrawer, setShowDrawer] = useState(false)
    const { screenType, isDesktop, isMobile, isTablet } = usePlatfomHook()

    const renderButtonName = (sideNavItem: SideNavigationItem) => {
        if (!isDesktop) {
            return null
        }
        return (
            <View style={sideNavItem?.containerStyle} key={sideNavItem.id}>
                <Pressable
                    testID={`navigation-button-${testID}-${sideNavItem.id}`}
                    style={[
                        style.navButtonNameStyle,
                        sideNavItem.containerStyle,
                    ]}
                    {...navigationItems}
                    onPress={() => {
                        delete sideNavItem.items
                        navigationItems?.onPress(sideNavItem, undefined)
                    }}
                >
                    <Text
                        style={[
                            style.navButtonNameStyle,
                            sideNavItem?.textStyle,
                        ]}
                    >
                        {sideNavItem.text}
                    </Text>
                </Pressable>
            </View>
        )
    }

    const handleTopNavLayout = (event) => {
        setTopNavContainerHeight(
            event.nativeEvent.layout?.height + event.nativeEvent.layout?.y,
        )
    }

    const adjustDrawer = () => {
        setShowDrawer(!showDrawer)
        hamburgerButton?.onPress?.()
    }

    const displayHamburgerMenu = useMemo(() => {
        if (isDesktop && !showResponsiveIcon) {
            return null
        }
        return (
            <Pressable
                {...hamburgerButton}
                onPress={() => adjustDrawer()}
                testID={`hamburger-button-${testID}`}
            >
                {hamburgerIcon ? (
                    <>{hamburgerIcon}</>
                ) : (
                    <Image
                        source={hamburgerImage}
                        style={style.hamburgerStyle}
                    />
                )}
            </Pressable>
        )
    }, [screenType, showDrawer])

    const getRightContainerItems = useMemo(() => {
        return (
            rightContainer?.map((item, index) => {
                let component = item.desktop || item.mobile || item.tablet
                if (isMobile) {
                    component = item.mobile
                } else if (isTablet) {
                    component = item.tablet
                } else if (isDesktop) {
                    component = item.desktop
                }
                return (
                    <View
                        key={`right-container-${index}`}
                        style={[style.rightIconStyle, rightIconStyle]}
                    >
                        {component}
                    </View>
                )
            }) || null
        )
    }, [rightContainer, screenType, rightIconStyle])

    return (
        <>
            <View
                style={[
                    style.container,
                    isDesktop && style.desktopPadding,
                    containerStyle,
                ]}
                onLayout={handleTopNavLayout}
            >
                <View style={[style.flexStyle]}>
                    {displayHamburgerMenu || null}
                    {headerLogoImage || null}
                    {isDesktop && (
                        <>{navigationItems?.items?.map(renderButtonName)}</>
                    )}
                </View>
                <View
                    style={[
                        style.flexStyle,
                        style.flexEndStyle,
                        rightContainerStyle,
                    ]}
                >
                    {searchInputBar}
                    {getRightContainerItems}
                </View>
            </View>
            <SideNavigation
                testID={testID}
                showResponsiveButton={false}
                showResponsiveDrawerOverride={true}
                showResponsiveDrawer={showDrawer}
                containerStyle={{ top: topNavContainerHeight }}
                {...navigationItems}
            />
        </>
    )
}

export default NavBar
