import React from 'react'
import { Pressable, View, Text } from 'react-native'
import style from './style'
import { FormHeaderProps } from './interface'

const FormHeader = (props: FormHeaderProps) => {
    const {
        titleText,
        iconRight,
        detailsText,
        iconPress,
        titleTextStyle,
        detailsTextStyle,
        containerStyle,
    } = props

    return (
        <View style={[style.mainContainer, containerStyle]}>
            <View style={style.subView}>
                <Text style={[style.titleTextStyle, titleTextStyle]}>
                    {titleText}
                </Text>
                <Pressable onPress={iconPress}>{iconRight}</Pressable>
            </View>
            {detailsText ? (
                <View style={style.detailViewStyle}>
                    <Text style={[style.detailsTextStyle, detailsTextStyle]}>
                        {detailsText}
                    </Text>
                </View>
            ) : (
                <></>
            )}
        </View>
    )
}

export default FormHeader
