import React from 'react'
import { Pressable, Text } from 'react-native'
import { styles } from './styles'
import { CardTitleProps } from './interface'

export const CardTitle = ({
    titleTextStyle,
    title,
    descriptionTextStyle,
    description,
    isMediumCard,
    priceTextStyle,
    price,
    cardTitleStyle,
    ...pressableProps
}: CardTitleProps) => {
    return (
        <Pressable style={[styles.productDetailContainer, cardTitleStyle]} {...pressableProps}>
            <Text style={[styles.titleTextStyle, titleTextStyle]}>{title}</Text>
            {description && (
                <Text
                    style={[styles.descriptionTextStyle, descriptionTextStyle]}
                >
                    {description}
                </Text>
            )}

            {isMediumCard && (
                <Text style={[styles.priceTextStyle, priceTextStyle]}>
                    {price}
                </Text>
            )}
        </Pressable>
    )
}
