import React, { useCallback, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import COLORS from '../../../utils/colors'
import { CalendarProvider, WeekCalendar } from 'react-native-calendars'
import moment from 'moment'
import styles from './styles'
import { WeeklyViewProps } from './interface'

export const WeeklyView: React.FC<WeeklyViewProps> = ({
    renderArrow,
    inputValue,
    setInputValue,
    selectedDate,
    setSelectedDate,
    handleOnDayPress,
    markedDates,
    dateInput,
    theme,
}) => {
    const handleNavigateWeek = useCallback(
        (direction) => {
            const newDate =
                direction === 'next'
                    ? moment(selectedDate).add(1, 'weeks')
                    : moment(selectedDate).subtract(1, 'weeks')
            setSelectedDate(newDate)
            setInputValue(newDate.format('MMM D, YYYY'))
        },
        [selectedDate, inputValue],
    )

    const [parentWidth, setParentWidth] = useState(0)

    const handleParentLayout = (event) => {
        const { width } = event.nativeEvent.layout
        setParentWidth(width)
    }

    const calendarWidth = parentWidth // 80% of parent width minus any padding/margin

    return (
        <View style={styles.container}>
            {dateInput}
            <View
                style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <TouchableOpacity
                    style={{
                        width: '10%',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                    onPress={() => handleNavigateWeek('prev')}
                >
                    {renderArrow('left')}
                </TouchableOpacity>
                <View style={{ width: '80%' }} onLayout={handleParentLayout}>
                    {parentWidth > 0 && (
                        <CalendarProvider
                            date={selectedDate.format('YYYY-MM-DD')}
                        >
                            <WeekCalendar
                                calendarWidth={calendarWidth}
                                firstDay={1}
                                onDayPress={handleOnDayPress}
                                markedDates={markedDates}
                                theme={{
                                    arrowStyle: { padding: 0 },
                                    calendarBackground: 'transparent',
                                    selectedDayBackgroundColor:
                                        COLORS.COLOR_BLUE,
                                    textDayFontWeight: '400',
                                    textDayFontSize: 14,
                                    textSectionTitleColor: COLORS.COLOR_GRAY,
                                    todayTextColor: COLORS.COLOR_BLACK,
                                    dayTextColor: COLORS.COLOR_LIGHTBLACK,
                                    textDisabledColor:
                                        COLORS.COLOR_GRAY_PLACEHOLDER,
                                    monthTextColor: COLORS.COLOR_LIGHTBLACK,
                                    textMonthFontWeight: '700',
                                    textMonthFontSize: 14,
                                    selectedDayTextColor:
                                        COLORS.COLOR_HIGHLIGHTBLUE,
                                    ...theme,
                                }}
                            />
                        </CalendarProvider>
                    )}
                </View>
                <TouchableOpacity
                    style={{
                        width: '10%',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                    }}
                    onPress={() => handleNavigateWeek('next')}
                >
                    {renderArrow('right')}
                </TouchableOpacity>
            </View>
        </View>
    )
}
